.nk-ibox {
    position: relative;
    text-align: center;

    .nk-ibox-icon {
        position: relative;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 4rem;
        line-height: 70px;
        text-align: center;

        img {
            width: 70px;
        }
    }

    .nk-ibox-title {
        margin-bottom: 20px;
    }
}

/* Titles */
.nk-ibox-title {
    font-size: 1.4rem;

    a {
        color: inherit;
        text-decoration: none;
    }
}

/* Button */
.nk-ibox-btn {
    margin-top: 20px;
}

/* Feature Hovered + Colors */
.nk-ibox-hover .nk-ibox-icon {
    transition: .3s background-color, .3s color;
    &::after {
        transition: .3s opacity;
    }
}
@each $name, $color in $colors {
    .nk-ibox-icon.nk-ibox-icon-bg-color-#{$name},
    .nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-#{$name},
    .nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-bg-color-#{$name} {
        color: #fff;
        background-color: $color;
        @if $name == "white" {
            color: $color_dark_1;
        }

        &::after {
            opacity: 0;
        }
    }
    .nk-ibox-icon.nk-ibox-icon-color-#{$name},
    .nk-ibox-hover.hover .nk-ibox-icon.nk-ibox-icon-hover-color-#{$name},
    .nk-ibox-hover:hover .nk-ibox-icon.nk-ibox-icon-hover-color-#{$name} {
        color: $color;
    }
}
