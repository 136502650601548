.nk-page-border {
    .nk-page-border-t,
    .nk-page-border-r,
    .nk-page-border-b,
    .nk-page-border-l {
        position: fixed;
        pointer-events: none;
        user-select: none;
        background-size: 100% 100%;
        z-index: 2000;
    }
    .nk-page-border-t,
    .nk-page-border-b {
        left: 0;
        width: 100%;
        height: 100px;
    }
    .nk-page-border-t {
        top: 0;
        background-image: url("../img/border-top.png");
    }
    .nk-page-border-b {
        bottom: 0;
        background-image: url("../img/border-bottom.png");
    }
    .nk-page-border-r,
    .nk-page-border-l {
        top: 0;
        width: 100px;
        height: 100%;
    }
    .nk-page-border-r {
        right: 0;
        background-image: url("../img/border-right.png");
    }
    .nk-page-border-l {
        left: 0;
        background-image: url("../img/border-left.png");
    }

    @media #{$media_xxs} {
        .nk-page-border-t,
        .nk-page-border-b {
            height: 50px;
        }
        .nk-page-border-r,
        .nk-page-border-l {
            width: 50px;
        }
    }
}
