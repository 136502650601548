.nk-accordion {
    .panel-heading {
        &::after {
            content: "";
            display: block;
            border-bottom: 1px solid;
            opacity: .2;
        }

        a {
            display: block;
            padding: 10px 20px;
            color: inherit;
            text-decoration: none;

            &::after {
                content: ">";
                float: right;
                margin-top: 2px;
                font-family: monospace;
                font-size: .8em;
                font-weight: 600;
                opacity: .6;
                transition: .2s transform;
                transform: rotate(90deg);
            }
            &.collapsed::after {
                transform: rotate(0);
            }

            &:not(.collapsed) {
                color: $color_main_1;
            }
        }
    }
}
