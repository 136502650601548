.nk-footer {
    position: relative;
    color: #eee;
    background-color: #000;
    z-index: 1;
}

// top corner
.nk-footer-top-corner {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    max-height: 250px;
    pointer-events: none;
    user-select: none;
}

// .nk-footer-logo {
//     text-align: center;
// }

// .nk-footer-social {
//     text-align: center;

//     > a {
//         display: inline-block;
//         width: 40px;
//         height: 40px;
//         margin: 6px 4px;
//         font-size: 1.15rem;
//         line-height: 40px;
//         color: inherit;
//         border: 1px solid;
//         border-radius: 20px;
//         transition: .3s color, .3s background-color, .3s border-color;
//         will-change: color, background-color;

//         &:hover,
//         &.hover {
//             color: $color_dark_1;
//             background-color: #fff;
//             border-color: #fff;
//         }
//     }
//     &.nk-footer-social-inverted {
//         > a {
//             &:hover,
//             &.hover {
//                 color: #fff;
//                 background-color: $color_dark_1;
//                 border-color: $color_dark_1;
//             }
//         }
//     }
// }
// .nk-footer-social-2 {
//     text-align: center;

//     > a {
//         display: inline-block;
//         margin: 15px;
//         color: inherit;
//         text-decoration: none;
//         transition: .3s opacity;
//         will-change: opacity;

//         &:hover,
//         &.hover {
//             opacity: .6;
//         }

//         > * {
//             display: block;
//             margin: 0;
//         }

//         > .icon {
//             margin-bottom: 5px;
//             font-size: 2rem;
//         }
//     }
// }

// .nk-footer-logos a {
//     display: inline-block;
//     margin: 25px;
// }
// .nk-footer-logos {
//     margin-right: -25px;
//     margin-left: -25px;

//     img {
//         height: auto;
//     }
// }

.nk-footer-links {
    > a {
        // color: inherit;
        //
        // &:hover,
        // &.hover {
        //     opacity: 0.8;
        // }
    }
    > span {
        margin-right: 10px;
        margin-left: 10px;
    }
}

// .nk-copyright,
// .nk-copyright-2 {
//     font-size: .95rem;
//     color: rgba(#fff, .7);
//     a {
//         color: #fff;
//     }
// }
// .nk-copyright {
//     padding: 2rem 0;
//     background-color: rgba($color_dark_1, .6);
// }
