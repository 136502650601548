.nk-box,
.nk-box-1,
.nk-box-2,
.nk-box-3,
.nk-box-4,
.nk-box-rounded,
.nk-box-rounded-1,
.nk-box-rounded-2,
.nk-box-rounded-3,
.nk-box-rounded-4 {
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}
// .nk-box-1,
// .nk-box-rounded-1 {
//     padding: 20px;
// }
.nk-box-2,
.nk-box-rounded-2 {
    padding: 40px;
}
.nk-box-3,
.nk-box-rounded-3 {
    padding: 60px;
}
// .nk-box-4,
// .nk-box-rounded-4 {
//     padding: 80px;
// }
// .nk-box-rounded,
// .nk-box-rounded-1,
// .nk-box-rounded-2,
// .nk-box-rounded-3,
// .nk-box-rounded-4 {
//     border-radius: 6px;
// }

/* line between boxes
   Used JS code to add .nk-box-line-top and .nk-box-last classnames */
// .nk-box-line {
//     // line
//     &::before {
//         content: "";
//         position: absolute;
//         display: block;
//         top: 2rem;
//         right: 0;
//         bottom: 2rem;
//         border-left: 1px solid;
//         opacity: .07;
//     }
//     &.nk-box-line-top::after {
//         content: "";
//         position: absolute;
//         display: block;
//         top: 0;
//         right: 2rem;
//         left: 2rem;
//         border-bottom: 1px solid;
//         opacity: .07;
//     }
//     &.nk-box-last::before {
//         content: none;
//     }
// }
